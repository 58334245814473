import React, {useEffect, useState} from 'react';
import {formatDate, getArticleLinks} from "../utils/articleUtil";
import {Image} from "react-datocms";
import Navigate from "../components/navigate";
import readingTime from '@danieldietrich/reading-time';
import {GatsbyImage} from "gatsby-plugin-image";
import cx from "classnames";

const ArticlePreview = props => {
    const className = cx(
        "",
        props.className,
    );
    const headingClass = cx(
        props.headingClass?.includes("text-") ? "" : "text-2xl",
        props.headingClass?.includes("mb-") ? "" : "mb-2",
        "block hover:underline",
        props.headingClass
    );
    const imageClass = cx(
        props.imageClass?.includes("mb-") ? "" : "mb-4",
        props.imageClass
    );
    const textClass = cx(
        "mb-0",
        props.textClass
    );
    const dateAsFlagClass = cx(
        "font-sans text-black lg:text-sm text-xs 4k:text-lg leading-snug",
        props.dateAsFlagClass
    );
    const authorAsFlagClass = cx(
        "font-sans text-denim lg:text-sm text-xs 4k:text-lg leading-snug",
        props.authorAsFlagClass
    );
    const titleAuthorDisplayClass = cx(
        "flex flex-col",
        props.titleAuthorDisplayClass
    );
    const categoryClass = cx(
        "block font-bold text-xs 4k:text-lg tracking-wider mb-2 text-denim-50 uppercase",
        props.categoryClass
    );
    const gatsbyImageClass = cx(
        "",
        props.gatsbyImageClass
    );

    const [articleLinks, setArticleLinks] = useState(getArticleLinks(props.data));

    useEffect(() => {
        if (!props.data) {
            return;
        }
        setArticleLinks(getArticleLinks(props.data));
    }, [props.data])

    const imageUrl = () => {
      if (props.data.cover.noModify) {
        return props.data.cover.url;
      }
      let url = `${props.data.cover.url}?`;
        let qualityGiven = false;
        if (props.imageMeta) {
            const keys = Object.keys(props.imageMeta);
            keys.forEach((key) => {
                url += `${key}=${props.imageMeta[key]}&`;
                !qualityGiven && (qualityGiven = key === 'q')
            });
        }
        if (!qualityGiven) {
            url += 'q=50';
        }
        return url;
    }

    const getDynamicImage = (cover) => {
        if (cover.responsiveImage) {
            cover.responsiveImage.alt = cover.responsiveImage.alt || props.data.heading;
            return <Image lazyLoad={props.lazyLoad || props.lazyLoad === undefined} data={cover.responsiveImage}/>
        } else {
            return <GatsbyImage className={gatsbyImageClass}
                                alt={props.data.heading}
                                loading={props.imageLoading || 'lazy'}
                                image={!props.fullImage && props.data.thumbnail ? props.data.thumbnail.gatsbyImageData : cover.gatsbyImageData}/>
        }
    }

    const imageComponent = () => {
        return <div><figure className={imageClass}>
          <Navigate href={articleLinks.getReadMore()}>
            {
              (props.imageFromUrl || (!props.data.thumbnail && !props.data.cover.gatsbyImageData))
                ?
                <img alt={props.data.heading} src={imageUrl()}/>
                :
                getDynamicImage(props.data.cover)
            }
          </Navigate>
        </figure></div>
    }

    const readTime = () => {
        const {minutes, word} = readingTime(props.data.content.replace(/<blockquote[\s\S]*?<\/blockquote>/g, ''));
        return minutes;
    }

    return (
        <div className={className} key={props.data.slug}
             style={props.style} data-datocms-noindex>
            {!props.noImage && props.data.cover?.url && imageComponent()}
            <div>
                {!props.noFlag && <Navigate className={categoryClass}
                                            href={articleLinks.categoryPath()}>{articleLinks.category.title}</Navigate>}
                <div className={titleAuthorDisplayClass}>
                    {props.dateAsFlag && <div className={"flex items-center"}>
                        <Navigate
                            className={authorAsFlagClass}
                            href={`/authors/${props.data.author.slug}`}>{props.data.author.name}</Navigate>
                        {/*<span style={{width: '6px', height: '6px'}} className={"rounded-default bg-black mx-1 lg:mx-2"}>&nbsp;</span>
                        <div className={dateAsFlagClass}>
                            {formatDate(props.data.publishedAt, props.publishedAtFormat)}
                        </div>*/}
                    </div>}
                    <Navigate className={headingClass}
                              href={articleLinks.getReadMore()} title={props.data.heading}>{props.data.heading}</Navigate>
                </div>
                {props.publishedAt &&
                <time className={'text-sm'}>{formatDate(props.data.publishedAt, props.publishedAtFormat)}</time>}
                {props.publishedAt && props.data.content && <span className={'text-sm'}> · {readTime()} min read</span>}
              {!props.noBody && <>
                {props.data.excerptNode?.childMarkdownRemark.html !== "" ? <div
                    className={`article-excerpt ${textClass}`}
                    dangerouslySetInnerHTML={{__html: props.data.excerptNode.childMarkdownRemark.html}}/> :
                  <p className={textClass}>{props.data.excerpt}</p>}
                    {!props.noReadMore &&
                    <Navigate className="text-sm text-blue-600 mr-2" href={articleLinks.getReadMore()}>Read More</Navigate>}
                </>}
                {!props.noAuthor && <>
                    <span className="text-13 block pt-1 text-black text-opacity-50">By <Navigate
                        className={'text-blue-600'}
                        href={`/authors/${props.data.author.slug}`}>{props.data.author.name}</Navigate>
                        {/*{!props.noPublishedDate &&
                        <time className="ml-2">{formatDate(props.data.publishedAt, 'mmmm dd, yyyy')}</time>}*/}
                    </span>
                </>}
            </div>
        </div>
    )
}
export default ArticlePreview;
